import character1 from "../../images/nft/carousel_img_1.png";
import character2 from "../../images/nft/carousel_img_2.png";
import character3 from "../../images/nft/carousel_img_3.png";
import character4 from "../../images/nft/carousel_img_4.png";
import character5 from "../../images/nft/carousel_img_5.png";

const data = [
  {
    thumb: character1,
  },
  {
    thumb: character2,
  },
  {
    thumb: character3,
  },
  {
    thumb: character4,
  },
  {
    thumb: character5,
  },
  {
    thumb: character4,
  },
  {
    thumb: character1,
  },
  {
    thumb: character2,
  },
];

export default data;
