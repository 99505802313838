import shape1 from "../../images/banner/shape1.png";  
import shape2 from "../../images/banner/shape2.png";  
import shape3 from "../../images/banner/shape3.png";  

const data = [
  shape1, 
  shape2, 
  shape3
];

export default data;
