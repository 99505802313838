import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 01 ",
        subtitle: "25% Soldout",
        text: "since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum actual teachings of the great explorer of the truth"
    }, 
    {
        icon: checkIcon,
        title: "Phase 02",
        subtitle: "50% Soldout",
        text: "Pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth"
    }, 
    {
        icon: checkIcon,
        title: "Phase 03",
        subtitle: "Metaverse Launch",
        text: "A complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04",
        subtitle: "70% Soldout",
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from expound the actual"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 05",
        subtitle: "80% Soldout", 
        text: "On the other hand, we denounce with righteous indignation and dislike men from expound the actual reproduced below for those interested"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 06",
        subtitle: "90% Soldout", 
        text: "Reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced"
    }, 
]

export default data;